import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ApiService from '../../services/Api'; // Adjust the import based on your file structure
import { Spin } from 'antd'; 
import Loader from '../Loader';
const CashbackOffer = () => {
    const [cashbackOffers, setCashbackOffers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCashbackOffers = async () => {
            setLoading(true);
            try {
                const result = await ApiService.getCoupontypeData('cashback'); // Fetch cashback offers from your API
                setCashbackOffers(result.data); // Assuming your API returns the data in `data` field
            } catch (err) {
                if (err.response && err.response.status === 404) {
                    setError(err.response.data.errors);
                }
                else if( err.status === 422){
                    setError(err.errors);
            
                 }
                else if (err.status === 404) {
                    setError(err.errors);
                }  else {
                    setError('Failed to load cashback offers.');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchCashbackOffers();
    }, []); // Empty dependency array to run effect only once on component mount

    if (loading) {
        
        return <Loader />;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="cashbackoffer-outer">
            <div className="container">
                <div className="cashbackoffer-inner">
                    <div className="row">
                        {cashbackOffers.map((offer, index) => (
                            <div className="item" key={index}>
                                <div className="item-inner">
                                    <span className="offer-sale">
                                      <p>
                                      {offer.amounttype === 'percentage' 
                                            ? `Up to ${Math.round(offer.discamount)}% off` 
                                            : `Up to $${Math.round(offer.discamount)} off`}
                                             </p> 
                                    </span>
                                    <div className="img-content">
                                        <img src={offer.image_url ? offer.image_url : '/assets/Image/no-image-icon-23485.png'} alt={offer.name} />
                                    </div>
                                    <div className="text-content">
                                        <Link to={`/cupondescription/${offer.id}`}  className='button'>
                                        {offer.amounttype === 'percentage' 
                                            ? `Earn Up to ${Math.round(offer.discamount)}% rewards` 
                                            : `Earn Up to $${Math.round(offer.discamount)} rewards`}
                                        </Link>
                                        <Link to='/rewards' className='rewards'>Rewards Rates & terms</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CashbackOffer;
