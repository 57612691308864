import React, { useState, useEffect } from 'react';
import { Masonry } from '@mui/lab';
import { FaStar } from 'react-icons/fa';
import { LuAlertCircle } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import ApiService from '../../../../services/Api'; // Make sure ApiService is correctly set up
import WhatNext from './WhatNext';  // Assuming WhatNext is used as modal or info box

const Income = () => {
  const [loading, setLoading] = useState(true);
  const [referrals, setReferrals] = useState([]);
  const [cashback, setCashback] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await ApiService.getIncomeData(); // Fetch data from API
        setReferrals(result.referrals);
        setCashback(result.cashback);
      } catch (err) {
        setError('Failed to load data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    setIsOpen(false);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="income-outer">
      <div className="container">
        <div className="income-inner">
          <Masonry columns={{ sm: 1, md: 2, lg: 3 }} spacing={2}>
            {/* Render Referrals */}
            {referrals.map((referral) => (
              <div className="item user-profile" key={referral.id}>
                <div className="item-inner">
                  <div className="img-content">
                    {/* Fallback to default image if no image URL is provided */}
                    <img src={referral.image_url || 'assets/Image/user1.png'} alt="User Profile" />
                  </div>
                  <div className="text-content">
                    <div className="user-name">
                      <h4>{referral.user.name}</h4>
                      <div className="alert-icon">
                        <Link to="#" onClick={() => handleOpenModal(referral)}>
                          <LuAlertCircle />
                        </Link>
                      </div>
                    </div>
                    {isOpen && selectedItem && selectedItem.id === referral.id && (
                      <WhatNext isOpen={isOpen} onClose={handleCloseModal} />
                    )}
                    <div className="id-date">
                      <div className="rafferal-id">Referral ID: #{referral.user.referral_code}</div>
                      <div className="date">Date: {new Date(referral.created_at).toLocaleDateString()}</div>
                    </div>
                    <div className="plan-commision">Plan commission: RS {referral.amount}</div>
                  </div>
                </div>
              </div>
            ))}

            {/* Render Cashback Offers */}
            {cashback.map((cashbackItem) => (
              <div className="item company-profile" key={cashbackItem.id}>
                <div className="item-inner">
                  <div className="top">
                    <img
                      src={cashbackItem.image_url || 'assets/Image/justdial.png'}
                      alt="Company"
                    />
                    <div className="rating">
                      <span className="star-icon">
                        <FaStar />
                      </span>
                      {cashbackItem.rating || '4.5'}
                    </div>
                    <p>( Get amount: {cashbackItem.amount} cashback )</p>
                    <div className="alert-icon">
                      <LuAlertCircle />
                    </div>
                  </div>
                  <div className="bottom">
                    <div className="img-content">
                      <img
                        src={cashbackItem.coupon.image_url || 'assets/Image/car.png'}
                        alt="Coupon Offer"
                      />
                    </div>
                    <div className="text-content">
                      <h5>{cashbackItem.coupon.name}</h5>
                      <p className="getoff">
                        ( Above Rs {cashbackItem.coupon.miniamount}) <span>Get {cashbackItem.amount} Off</span>
                      </p>
                      <p className="offer">
                        Offer till {new Date(cashbackItem.coupon.enddate).toLocaleDateString()}
                        <Link className="button">View</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Masonry>
        </div>
      </div>
    </div>
  );
};

export default Income;
