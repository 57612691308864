import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ApiService from '../../../services/Api'; // Adjust the import based on your file structure
import Slider from 'react-slick';
import { Spin } from 'antd';
import { FaAngleRight } from 'react-icons/fa6';
import Loader from '../../Loader';
const Voucher = () => {
    const [vouchers, setVouchers] = useState([]); // State to store fetched vouchers
    const [loading, setLoading] = useState(false); // State to handle loading status
    const [error, setError] = useState(null); // State to handle errors

    useEffect(() => {
        const fetchVouchers = async () => {
            setLoading(true);
            try {
                // Fetch vouchers from your API
                const result = await ApiService.getCoupontypeData('voucher'); 
                setVouchers(result.data); // Assuming your API returns data in the `data` field
            } catch (err) {
                if (err.response && err.response.status === 404) {
                    setError(err.response.data.errors);
                } else if (err.status === 422) {
                    setError(err.errors);
                } else if (err.status === 404) {
                    // setError(err.errors);
                } else {
                    setError('Failed to load vouchers.');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchVouchers(); // Call the fetch function
    }, []); // Run only once when the component mounts

    // If the data is still loading, show a loading spinner
    if (loading) {
        
        return <Loader />;
    }

    // If there is an error fetching data, display an error message
    if (error) {
        return <div>{error}</div>;
    }

    // If there are no vouchers, display a "No vouchers available" message
    if (vouchers.length === 0) {
        return (
            <div className="store-outer">
                <div className="container">
                    <div className="store-inner">
                        <div className="heading">
                            <h5>No Voucher Data Found</h5>
                        </div>
                        <div className="no-store-message">
                            <p>No Voucher data available at the moment. Please try again later.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // Slider settings for the display
    const settings = {
        slidesToShow: 4,
        slidesToScroll: 1, // Scroll one slide at a time
        infinite: false, // Disable infinite looping to prevent repeating data
        arrows: true, // Show navigation arrows
        dots: false, // Disable dots pagination
        rows: 1, // Single row of slides
        responsive: [
            {
                breakpoint: 1200, 
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <div className="voucher-outer">
            <div className="container">
                <div className="voucher-inner">
                    <div className="heading">
                        <h3>Vouchers Coupons</h3>
                        <Link to="/voucher">View All <span><FaAngleRight /></span></Link>
                    </div>
                    <Slider {...settings}>
                        {vouchers.map((voucher, index) => {
                            // Set dynamic styles based on voucher data
                            const bgColor = voucher.bgimg || "#A9D5B2"; // Default background color
                            const textColor = voucher.pcolor || '#002408'; // Default text color
                            const rsColor = voucher.rscolor || '#E31837'; // Default Rs color
                            const cashbackColor = voucher.cashcolor || '#046851'; // Default cashback color
                            const linkColor = voucher.linkcolor || '#FFF'; // Default link color

                            return (
                                <div className="item" key={index}>
                                    <div className="item-inner" style={{ backgroundColor: bgColor }}>
                                        <div className="text-content">
                                            <h3 style={{ color: textColor }}>{voucher.name}</h3>
                                            
                                            <span style={{ color: rsColor }}>Rs {Math.round(voucher.miniamount)} - {Math.round(voucher.maxamount)}</span>
                                            <p className="cashback" style={{ color: cashbackColor }}>
                                            {voucher.amounttype === 'percentage' 
                                            ? `Get to ${Math.round(voucher.discamount)}% off` 
                                            : `Get to $${Math.round(voucher.discamount)} off`}
                                            </p>
                                            <p className="link-button">
                                                <Link
                                                    to={`/cupondescription/${voucher.id}`} // Assuming a dynamic link to the coupon details
                                                    className="button"
                                                    style={{ color: linkColor, backgroundColor: textColor }}
                                                >
                                                    Get Now
                                                </Link>
                                            </p>
                                        </div>
                                        <div className="img-content">
                                            <img src={voucher.image_url || '/assets/Image/no-image-icon-23485.png'} alt={voucher.name} />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Voucher;
