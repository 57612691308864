import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import ApiService from '../../../services/Api'; // Assuming ApiService is set up for fetching coupons
import { Spin } from 'antd'; 
import Loader from '../../Loader';
const Treandincupon = () => {
    const [coupons, setCoupons] = useState([]); // State to store coupon data
    const [loading, setLoading] = useState(true); // Loading state
    const [errors, setErrors] = useState(null); // Errors state for error handling

    // const settings = {
    //     slidesToShow: 5,
    //     rows: 2,
    //     nav: true,
    //     responsive: [
    //         {
    //             breakpoint: 1200,
    //             settings: {
    //                 slidesToShow: 4,
    //             }
    //         },
    //         {
    //             breakpoint: 991,
    //             settings: {
    //                 slidesToShow: 2,
    //             }
    //         },
    //         {
    //             breakpoint: 576,
    //             settings: {
    //                 slidesToShow: 1,
    //             }
    //         }
    //     ]
    // };
    const settings = {
        slidesToShow: 5,
        slidesToScroll: 1, // Scroll one slide at a time
        infinite: false, // Disable infinite looping to prevent repeating data
        arrows: true, // Show navigation arrows
        dots: false, // Disable dots pagination
        rows: 1, // Single row of slides
        responsive: [
            {
                breakpoint: 1200, 
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    // Fetching the coupon data when the component mounts
    useEffect(() => {
        const fetchCoupons = async () => {
            try {
                const result = await ApiService.getTrendingCoupons(); // Update with your API call for coupons
                if (result.status === 200 || result.status === 201) {
                    setCoupons(result.data); // Set the fetched coupons to state
                } else {
                    // setErrors('Failed to fetch trending coupons');
                }
            } catch (error) {
                console.error("Error fetching coupons:", error);
                // setErrors('An error occurred while fetching coupons.');
            } finally {
                setLoading(false); // Set loading to false after the request completes
            }
        };

        fetchCoupons();
    }, []); // Empty dependency array means this will run once on mount

 
    if (loading) {
        
        return <Loader />;
    }

 

    // If the coupons array is empty, show a message inside the slider
    const noCouponsAvailable = coupons.length === 0;

    return (
        <div className="tranding-outer">
            <div className="container">
                <div className="tranding-inner">
                    <div className="heading">
                        <h3>Trending Coupons & Offers</h3>
                    </div>
                    <Slider {...settings}>
                        {noCouponsAvailable ? (
                            <div className="item no-coupons">
                                <div className="item-inner">
                                    <p>No coupons available at the moment</p> {/* Message for empty data */}
                                </div>
                            </div>
                        ) : (
                            coupons.map((coupon, index) => (
                                <div className="item" key={index}>
                                    <div className="item-inner">
                                        <div className="img-content">
                                            <img 
                                                src={coupon.image_url ? coupon.image_url : '/assets/Image/no-image-icon-23485.png'} 
                                                alt={coupon.shop_name} 
                                            /> 
                                        </div>

                                        <div className="text-content">
                                            <p>
                                                {coupon.amounttype === 'percentage' 
                                                    ? `Up to ${Math.round(coupon.discamount)}% off` 
                                                    : `Up to $${Math.round(coupon.discamount)} off`}
                                            </p>
                                            <div className="cupon-button">
                                                <Link to={`/cupondescription/${coupon.id}`} className='cupon-code-button'>
                                                    <span className='cupon'>Get Coupons</span>
                                                    <span className='code'> XXXX </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Treandincupon;
