import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ApiService from '../../../../services/Api';  // Import your ApiService
import Loader from '../../../Loader'; // Assuming you have a custom Loader component

const CuponOffer = () => {
    const [couponOffers, setCouponOffers] = useState([]);  // State to store coupon offers
    const [loading, setLoading] = useState(false);         // State to track loading status
    const [error, setError] = useState(null);              // State to track errors

    // Fetch coupon offers when component mounts
    useEffect(() => {
        const fetchCouponOffers = async () => {
            setLoading(true); // Set loading to true before making the request
            try {
                const result = await ApiService.PurchasedCoupontype('discount', 'used');  // API call for coupons data
                setCouponOffers(result.data); // Set the fetched data into the state
            } catch (err) {
                if (err.response && err.response.status === 404) {
                    setError('No coupon offers found.');
                } else if (err.response && err.response.status === 422) {
                    setError('Invalid request.');
                } else {
                    setError('Failed to load coupon offers.');
                }
            } finally {
                setLoading(false); // Set loading to false after the request finishes
            }
        };

        fetchCouponOffers(); // Call the fetch function on component mount
    }, []); // Empty dependency array to run the effect only once when the component mounts

    // Show loader while fetching data
    if (loading) {
        return <Loader />;  // Display loader while fetching
    }

    // Show error message if there was an issue fetching data
    if (error) {
        return <div>{error}</div>;  // Display error if there was one
    }

    if (couponOffers.length === 0) {
        return (
            <div className="no-data mt-3 mb-3">
                <h3>No Used coupon Available</h3>
                <p>Sorry, there are no coupon used at the moment.</p>
            </div>
        );
    }
    return (
        <div className="cupon-offer-outer">
            <div className="container">
                <div className="cupon-offer-inner">
                    <div className="heading">
                        <h3>COUPONS & OFFERS</h3>
                    </div>

                    <div className="cupon-offer row">
                        {couponOffers.map((offer, index) => (
                            <div className="item" key={index}>  
                                <div className="item-inner">
                                    <span className="offer-sale">
                                    <p>
                                        {offer.amounttype === 'percentage'
                                            ? `Up to ${Math.round(offer.discamount)}% off`
                                            : `Up to $${Math.round(offer.discamount)} off`}
                                    </p>
                                    </span>
                                    <div className="img-content">
                                        <img src={offer.image_url || '/assets/Image/no-image-icon-23485.png'} alt="Offer" />
                                    </div>
                                    <div className="text-content">
                                    <Link to="#" className="button">
                                       <b>{(offer.unique_code)}</b> 
                                       
                                        </Link>

                                        <Link className="rewards" to={`/cupondescription/${offer.id}`}>
                                            {offer.amounttype === 'percentage' 
                                            ? `Earn Up to ${offer.discamount}% Offers` 
                                            : `Earn Up to $${offer.discamount} Offers`}
                                            </Link>
                                            
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CuponOffer;
