import React from 'react'
import { Link } from 'react-router-dom'

const CuponCodeEnter = ({ onOpenUserCupon }) => {

    return (
        <>
            <div className="enter-cupon-code">
                <div className="cupon-code-info">
                    <div className="heading"><h3>Enter coupon code</h3></div>
                    <input type="text" placeholder='Enter coupon code' />
                </div>

                <div className="submit-button">
                    <Link className="button" onClick={onOpenUserCupon}>Submit</Link>
                </div>
            </div>
        </>
    )
}

export default CuponCodeEnter
