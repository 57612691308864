import React, { useEffect, useState } from 'react';
import { FaAngleRight, FaCirclePlus } from 'react-icons/fa6';
import { Link } from 'react-router-dom'
import ApiService from '../../../services/Api';
import { Spin } from 'antd'; 
import Loader from '../../Loader';
const Draftcupon = () => { 

    const [cupon, setCoupon] = useState([]); // State to hold coupon data
    const [loading, setLoading] = useState(true); // Loading state
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState({});
    
    useEffect(() => {
        handleGetCouponData();
    }, []);

    const handleGetCouponData = async () => {
        try {
            const result = await ApiService.getDraftCouponData(); 
            if (result.status === 200 || result.status === 201) {
                setCoupon(result.data);
            
                setShow(true);
            } else {
                // setSubmitError('Failed to fetch coupons. Please try again.');
            }
        } catch (err) {
            if (err.status === 422) {
                setErrors(err.errors); // Handle specific validation errors, if applicable
            } else {
                // setSubmitError(err.message || 'An unexpected error occurred.');
            }
        } finally {
            setLoading(false); // Set loading to false after data is fetched
        }
    };
    return (
        <>
            <div className="draft-cupon-page draft-cupon-outer">
                <div className="container">
                    <div className="draft-cupon-inner cupon-row">
                        <div className="row">
                        {loading ? (
                              <Loader />
                            ) : cupon.length === 0 ? (
                                <p>No draft coupons available.</p>
                            ) : 
                                cupon.map((i) => {
                                    return (
                                        <div className="item col-12 col-md-6 col-xl-4">
                                            <div className="item-inner">
                                                <div className="top">
                                                    <div className="img-content">
                                                    <img src={i.image_url ? i.image_url : '/assets/Image/no-image-icon-23485.png'} 
                                                            alt={i.name} 
                                                        />
                                                        <h2>{i.btype}</h2>
                                                        <span className='cupon-mode'>{i.cuponused}</span>
                                                    </div>
                                                   
                                                    <div className="add-new">
                                                        <Link to={'/newdeals'}><FaCirclePlus /> Add new deals</Link>
                                                    </div>
                                                </div>
                                                <div className="bottom row">
                                                    <div className="col-6"> <b> Cat: </b> {i.category_name}</div>
                                                    <div className="col-6"><b> Started:: </b>  {i.startdate}</div>
                                                    <div className="col-6"><b>Sub-Cat: </b> {i.subcategory_name}</div>
                                                    <div className="col-6"><b> Ended: </b>  {i.enddate}</div>
                                                    <div className="col-6"><b> Deals: </b>  {i.ctype}</div>
                                                    <div className="col-6"><Link to={`/coupons/${i.id}`}>View</Link></div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Draftcupon
