import React, { useState } from 'react';
import { FaX } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import ApiService from '../../services/Api';

const Login = ({ onClose, onOpenMemberSignUp }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
});
const [rememberMe, setRememberMe] = useState(false);
const [errors, setErrors] = useState({});
const [submitError, setSubmitError] = useState('');
const [successMessage, setSuccessMessage] = useState('');

const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
        ...prevData,
        [name]: value
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' })); 
    setSubmitError(''); 
};

const handleCheckboxChange = () => {
    setRememberMe((prev) => !prev);
};

const validateForm = () => {
    const newErrors = {};
    if (!formData.email) newErrors.email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email is invalid';

    if (!formData.password) newErrors.password = 'Password is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; 
};

const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return; 
 
    try {
        const result = await ApiService.UserLogin(formData);
        if (result.status === 200) {
          localStorage.removeItem('UserData');
          localStorage.removeItem('role');
          localStorage.removeItem('token');
          localStorage.removeItem('is_approved');
          localStorage.removeItem('sub_manager');
          localStorage.removeItem('loyalty_points');
          localStorage.removeItem('profile_pic');
          localStorage.removeItem('location');
      
          localStorage.setItem('UserData', JSON.stringify(result));
          localStorage.setItem('role', result.role);
          localStorage.setItem('loyalty_points', result.success.loyalty_points);
          localStorage.setItem('token', result.success.token);
          localStorage.setItem('is_approved', result.success.is_approved);
          localStorage.setItem('profile_pic', result.success.image_url);
          localStorage.setItem('location', result.success.location);
            if (rememberMe) {
                localStorage.setItem('merchantEmail', formData.email); // Store email in local storage
            }
            setSuccessMessage('Login successful! Redirecting...'); 
            setTimeout(() => {
                onClose(); 
            }, 2000);
            window.location.href = '/'; 
        }else {
          setSubmitError('Login failed. Please try again.');
      }
  } catch (err) {
    const status = err.status;
    const Errors = err.errors;
      if (status === 401) {
          setSubmitError(Errors);
      }else if (status === 403) {
        setSubmitError(Errors); 
    } else if (status === 404) {
        setSubmitError(Errors);
    }        
       else {
          setSubmitError(err.message || 'An unexpected error occurred.'); 
      }
  }
};
  return (
    <>
      <div className="login-outer member-login">
        <div className="login-inner row">
          <div className="col-12 col-sm-6 left">
            <div className="img-content">
              <img src="/assets/Image/Discount.png" alt="" />
              <img src="/assets/Image/voucher.png" alt="" />
            </div>
          </div>
          <div className="col-12 col-sm-6 right">
            <button onClick={onClose}><FaX /></button>
            <div className="inner">

              <div className="heading">
                <h3>Sign in</h3>
              </div>
              <form method="post" onSubmit={handleSubmit}>
                <input
                    type="email"
                    name='email'
                    id='email'
                    placeholder='E-mail id'
                    value={formData.email}
                    onChange={handleChange}
                />
                {errors.email && <span className="error">{errors.email}</span>}

                <input
                    type="password"
                    name='password'
                    id='password'
                    placeholder='Password'
                    value={formData.password}
                    onChange={handleChange}
                />
                {errors.password && <span className="error">{errors.password}</span>}

                <div className="privacy-check remember-me">
                    <input
                        type="checkbox"
                        checked={rememberMe}
                        onChange={handleCheckboxChange}
                    />
                    <label>Remember Me</label>
                </div>

                <Link to='' className='button' onClick={handleSubmit}>Sign In</Link>

                <div className="sign-up">Create New Account ? <Link to={''} onClick={() => {
                onOpenMemberSignUp();
                }}>Sign Up</Link></div>

                {submitError && <span className="error">{submitError}</span>}
                {successMessage && <span className="success">{successMessage}</span>}
            </form>
            </div>
          </div>
        </div>
      </div>
      </>
  )
}

export default Login