import React, { useEffect, useState } from 'react';
import { MdEditSquare } from 'react-icons/md';
import { Link } from 'react-router-dom';
import ApiService from '../../../services/Api'; // Assuming ApiService is your API service for data fetching
import { Spin } from 'antd'; // For showing a loading spinner
import Loader from '../../Loader'

const Profile = () => {
    const [userData, setUserData] = useState(null); // Store user profile data
    const [loading, setLoading] = useState(true); // Loading state
    const [editMode, setEditMode] = useState(false); // To toggle between edit and view mode
    const [errors, setErrors] = useState({}); // For handling form validation errors
    const [formData, setFormData] = useState({}); // For storing form data when editing
    const [profileImage, setProfileImage] = useState(null); // For storing the new profile image file
    const [successMessage, setSuccessMessage] = useState('');
    // Fetch user profile data on component mount
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const result = await ApiService.getUserProfile(); // Fetch user profile from API
                if (result.status === 200 || result.status === 201) {
                    const { dob } = result.data; // Get dob from the response data

                    if (dob) {
                        // Only split dob if it exists and is in the expected format (YYYY-MM-DD)
                        const [dobYear, dobMonth, dobDay] = dob.split('-'); // Split DOB into year, month, day
                        setFormData({
                            ...result.data,  // Spread the rest of the user data
                            dob_day: dobDay,
                            dob_month: dobMonth,
                            dob_year: dobYear,
                        });
                    } else {
                        // If dob doesn't exist, just set form data without dob fields
                        setFormData({
                            ...result.data,
                            dob_day: '',
                            dob_month: '',
                            dob_year: '',
                        });
                    }
    
                    setUserData(result.data);
                } else {
                    setErrors({ message: 'Failed to load user data' });
                }
            } catch (err) {
                setErrors({ message: err.message || 'An error occurred' });
            } finally {
                setLoading(false); // Set loading to false after data is fetched
            }
        };

        fetchUserData();
    }, []);

    // Handle change in form fields when editing
    const handleInputChange = (e) => {
        const { name, value, type, files } = e.target;
        
        if (type === 'file') {
            const file = files[0];
            setProfileImage(file);

            // File validation logic
            const fileErrors = {};
            if (file) {
                // Validate file type (image/jpeg, image/png, etc.)
                const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
                if (!validImageTypes.includes(file.type)) {
                    fileErrors.profile_image = 'Please select a valid image (JPEG, PNG, JPG)';
                }

                // Validate file size (max 5MB)
                if (file.size > 5 * 1024 * 1024) {
                    fileErrors.profile_image = 'File size should not exceed 5MB';
                }
            }

            setErrors((prevErrors) => ({
                ...prevErrors,
                ...fileErrors, // Merge file errors with the other form errors
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    // Custom validation function
    const validateForm = () => {
        const errors = {};
    
        // Validate Name
        if (!formData.name) {
            errors.name = 'User Name is required';
        }
    
        // Validate Email
        if (!formData.email) {
            errors.email = 'E-mail ID is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Please enter a valid email address';
        }
    
        // Validate Date of Birth
        const { dob_day, dob_month, dob_year } = formData;
        if (!dob_day || !dob_month || !dob_year) {
            errors.dob = 'Date of birth is required';
        } else {
            // Validate Day, Month, and Year format
            if (!/^\d{2}$/.test(dob_day) || parseInt(dob_day) < 1 || parseInt(dob_day) > 31) {
                errors.dob = 'Day must be a two-digit number between 01 and 31';
            } else if (!/^\d{2}$/.test(dob_month) || parseInt(dob_month) < 1 || parseInt(dob_month) > 12) {
                errors.dob = 'Month must be a two-digit number between 01 and 12';
            } else if (!/^\d{4}$/.test(dob_year) || parseInt(dob_year) < 1900 || parseInt(dob_year) > new Date().getFullYear()) {
                errors.dob = 'Year must be a four-digit number and cannot be in the future';
            } else {
                // Check if the date is valid (e.g., 31st February is invalid)
                const date = new Date(dob_year, dob_month - 1, dob_day);
                if (date.getDate() !== parseInt(dob_day)) {
                    errors.dob = 'Invalid date of birth';
                }
            }
        }
    
        // Validate Gender
        if (!formData.gender) {
            errors.gender = 'Gender is required';
        }
    
        // Validate Mobile number
        if (!formData.mobile) {
            errors.mobile = 'Mobile number is required';
        } else if (!/^\d{10}$/.test(formData.mobile)) {
            errors.mobile = 'Mobile number must be 10 digits';
        }
    
        // Validate Location
        if (!formData.location) {
            errors.location = 'Location is required';
        }
    
        if (profileImage) {
            // Validate file type (image/jpeg, image/png, etc.)
            const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            if (!validImageTypes.includes(profileImage.type)) {
                errors.profile_image = 'Please select a valid image (JPEG, PNG, JPG)';
            }

            // Validate file size (max 5MB)
            if (profileImage.size > 5 * 1024 * 1024) {
                errors.profile_image = 'File size should not exceed 5MB';
            }
        }

        setErrors(errors); // Set errors to display
        return Object.keys(errors).length === 0; // Return true if there are no errors
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!validateForm()) {
            return; // If form is invalid, don't proceed
        }
    
        try {
            const formDataToSend = new FormData();
            
            // Combine DOB day, month, and year into a single string
            const dob = `${formData.dob_year}-${formData.dob_month}-${formData.dob_day}`;
            formDataToSend.append('dob', dob);
            
            // Append other form fields to the FormData object
            Object.keys(formData).forEach(key => {
                if (formData[key]) {
                    formDataToSend.append(key, formData[key]);
                }
            });
    
            // Append the profile image if it's selected
            if (profileImage) {
                formDataToSend.append('profile_image', profileImage);
            }
    
            // Print out FormData for debugging
            for (let pair of formDataToSend.entries()) {
                console.log(pair[0] + ': ' + pair[1]);
            }
    
            // Call the API to update user profile
            const result = await ApiService.updateUserProfile(formDataToSend);
            if (result.status === 200 || result.status === 201) {
                  
                setUserData(result.data); // Update user data in state after successful edit
                localStorage.setItem('profile_pic',result.data.image_url);
                setEditMode(false); // Exit edit mode
                
                setSuccessMessage('Profile updated successfully!'); 
                setTimeout(() => {
                    setSuccessMessage('');
               }, 3000);
            } else {
                setErrors({ message: 'Failed to update profile' });
            }
        } catch (err) {
            setErrors({ message: err.message || 'An error occurred' });
        }
    };

    if (loading) {
        
        return <Loader />;
    }

    // If userData is available, display the form with values
    return (
        <div className="profile-outer">
            <div className="container">
                <div className="profile-inner">
                    <div className="row">
                        <div className="col-md-3 col-12 img-content">
                            <div className="edit-icon" onClick={() => setEditMode(!editMode)}>
                                <MdEditSquare />
                            </div>
                            <div className="profile-img">
                                <img src={userData?.image_url || 'assets/Image/profile.png'} alt="Profile" />
                            </div>
                            <div className="user-name">
                                <h2>{userData?.name}</h2>
                            </div>
                        </div>
                        <div className="col-md-9 col-12 text-content">
                        {successMessage && <div className="success" style={{ color: 'green', marginBottom: '15px' }}>{successMessage}</div>}
                            <form onSubmit={handleSubmit} method="POST" className="row" encType="multipart/form-data">
                                <div className="col-sm-6 col-12">
                                    <label htmlFor="username">User Name</label>
                                    <input
                                        type="text"
                                        id="username"
                                        name="name"
                                        value={formData?.name || ''} // Ensure value is correctly set
                                        onChange={handleInputChange}
                                        placeholder="Enter your name"
                                        disabled={!editMode}
                                    />
                                    {errors.name && <span className="error">{errors.name}</span>}
                                </div>
                                <div className="col-sm-6 col-12">
                                    <label htmlFor="email">E-mail ID</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData?.email || ''} // Ensure value is correctly set
                                        onChange={handleInputChange}
                                        placeholder="Enter E-mail id"
                                        disabled={!editMode}
                                    />
                                    {errors.email && <span className="error">{errors.email}</span>}
                                </div>
                                {/* Date of Birth Fields */}
                                <div className="col-sm-6 col-12">
                                    <label htmlFor="dob">Date of birth</label>
                                    <div className="birth-date">
                                        <input
                                            type="text"
                                            id="date"
                                            name="dob_day"
                                            value={formData?.dob_day || ''}
                                            onChange={handleInputChange}
                                            placeholder="DD"
                                            disabled={!editMode}
                                        />
                                        <input
                                            type="text"
                                            id="month"
                                            name="dob_month"
                                            value={formData?.dob_month || ''}
                                            onChange={handleInputChange}
                                            placeholder="MM"
                                            disabled={!editMode}
                                        />
                                        <input
                                            type="text"
                                            id="year"
                                            name="dob_year"
                                            value={formData?.dob_year || ''}
                                            onChange={handleInputChange}
                                            placeholder="YYYY"
                                            disabled={!editMode}
                                        />
                                    </div>
                                    {errors.dob && <span className="error">{errors.dob}</span>}
                                </div>
                                
                                {/* Gender */}
                                <div className="col-sm-6 col-12 gender">
                                    <label htmlFor="gender">Gender</label>
                                    <span>
                                        <input
                                            type="radio"
                                            id="male"
                                            name="gender"
                                            className="gender-custom-radio" 
                                            value="male"
                                            checked={formData?.gender === 'male'}
                                            onChange={handleInputChange}
                                            disabled={!editMode}
                                        />{' '}
                                        Male
                                    </span>
                                    <span>
                                        <input
                                            type="radio"
                                            id="female"
                                            name="gender"
                                            value="female"
                                            className="gender-custom-radio" 
                                            checked={formData?.gender === 'female'}
                                            onChange={handleInputChange}
                                            disabled={!editMode}
                                        />{' '}
                                        Female
                                    </span>
                                    {errors.gender && <span className="error">{errors.gender}</span>}
                                </div>

                                {/* Mobile Number */}
                                <div className="col-sm-6 col-12">
                                    <label htmlFor="mnumber">Mobile number</label>
                                    <input
                                        type="number"
                                        id="mnumber"
                                        name="mobile"
                                        value={formData?.mobile || ''}
                                        onChange={handleInputChange}
                                        placeholder="Enter mobile number"
                                        disabled={!editMode}
                                    />
                                    {errors.mobile && <span className="error">{errors.mobile}</span>}
                                </div>

                                {/* Location */}
                                <div className="col-sm-6 col-12">
                                    <label htmlFor="location">Location</label>
                                    <input
                                        type="text"
                                        id="location"
                                        name="location"
                                        value={formData?.location || ''}
                                        onChange={handleInputChange}
                                        placeholder="Enter your location"
                                        disabled={!editMode}
                                    />
                                    {errors.location && <span className="error">{errors.location}</span>}
                                </div>

                                {/* Profile Image */}
                                {editMode && (
                                    <div className="col-sm-6 col-12">
                                        <label htmlFor="profile_image">Profile Image</label>
                                        <input
                                            type="file"
                                            id="profile_image"
                                            name="profile_image"
                                            accept="image/*"
                                            onChange={handleInputChange}
                                        />
                                          {errors.profile_image && <span className="error">{errors.profile_image}</span>}
                                    </div>
                                )}

                                {/* Submit Button */}
                                <div className="col-12 submit-form">
                                    {editMode ? (
                                        <Link to="#" onClick={handleSubmit} className="button">Save</Link>
                                    ) : (
                                        <Link to="#" onClick={() => setEditMode(true)} className="button">Edit</Link>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
