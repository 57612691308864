import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ApiService from '../../services/Api'; // Adjust the import based on your file structure
import { Spin } from 'antd'; 
import Loader from '../Loader';
const VoucherAll = () => {
    const [vouchers, setVouchers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchVouchers = async () => {
            setLoading(true);
            try {
                const result = await ApiService.getCoupontypeData('voucher'); // Fetch vouchers from your API
                setVouchers(result.data); // Assuming your API returns the data in the `data` field
            } catch (err) {
                if (err.response && err.response.status === 404) {
                    setError(err.response.data.errors);
                }
                else if( err.status === 422){
                    setError(err.errors);
            
                 }
                else if (err.status === 404) {
                    setError(err.errors);
                } else {
                    setError('Failed to load vouchers.');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchVouchers();
    }, []); // Empty dependency array to run effect only once on component mount

    if (loading) {
        
        return <Loader />;
    }

    if (error) {
        return <div> {error}</div>;
    }

    return (
        <div className="voucherpage-outer">
            <div className="container">
                <div className="voucherpage-inner">
                    <div className="row">
                        {vouchers.map((voucher, index) => (
                            <div className="item col-md-4 col-xl-3 col-12 col-sm-6" key={index}>
                                <div className="item-inner">
                                    <div className="text-content">
                                        <p>{voucher.name}</p>
                                        <span>Rs {Math.round(voucher.miniamount)} - {Math.round(voucher.maxamount)}</span>
                                        <p className='cashback'>
                                        {voucher.amounttype === 'percentage' 
                                            ? `Get to ${Math.round(voucher.discamount)}% off` 
                                            : `Get to $${Math.round(voucher.discamount)} off`}
                                            
                                            </p>
                                        <p className="link-button">
                                             <Link to={`/cupondescription/${voucher.id}`} className='button'>Get Now</Link>
                                        </p>
                                    </div>
                                    <div className="img-content">
                                        <img src={voucher.image_url ? voucher.image_url : '/assets/Image/no-image-icon-23485.png'} alt={voucher.name} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VoucherAll;
