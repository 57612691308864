import React, { useState } from 'react';
import { Container, Dropdown, DropdownItem, Modal, Nav, Navbar } from 'react-bootstrap';
import { FaRegBell, FaSearch } from 'react-icons/fa';
import { IoLogOut } from 'react-icons/io5';
import { MdOutlineQrCodeScanner } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import Notification from '../User/Notification';
import { BaseUrl } from '../BaseURL/BaseUrl';
import { useAuth } from '../../context/AuthContext'; 
const OperatorHeader = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const navigate = useNavigate();
    const { logout } = useAuth(); 
    const handleClose = () => setShowModal(false);

    const handlenotificition = () => {
        setModalContent(<Notification onClose={handleClose} />);
        setShowModal(true);
    };

    const handleLogout = async () => {
        try {
            // API call to log out the user
            const response = await fetch(`${BaseUrl}/manager/logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`, // Send token if needed
                },
            });

            if (response.ok) {
                
                // Clear authentication token or session
                localStorage.removeItem('UserData');
                localStorage.removeItem('role');
                localStorage.removeItem('token');
                localStorage.removeItem('is_approved');
                localStorage.removeItem('sub_manager');
                localStorage.removeItem('loyalty_points');
                localStorage.removeItem('profile_pic');
                localStorage.removeItem('location');
                logout(); 
                // Redirect to login page
                navigate('/home');
            } else {
                console.error('Logout failed');
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    return (
        <>
            <Navbar expand="lg" className="merchantheader">
                <div className="top">
                    <Container>
                        <div className="header-inner row">
                            <div className="logo col-2">
                                <div className="img-content">
                                    <img src="assets/Image/logo.png" alt="Header Logo" />
                                </div>
                            </div>
                            <div className="info col-8">
                                <div className="search">
                                    <input type="text" name="search" id="search" placeholder='Search for the coupons, deals, and offers' />
                                    <button><FaSearch /></button>
                                </div>
                                <Link to={'/operatorloyaltypoints'} className="points">
                                    <img src="assets/Image/Coins.png" alt="Coins Logo" />
                                    <p>100</p>
                                </Link>
                                <div className="subscribe">
                                    <Link onClick={handlenotificition}><FaRegBell /></Link>
                                </div>
                                <div className="scanner">
                                    <Link to={'/scanneroption'}><MdOutlineQrCodeScanner /></Link>
                                </div>
                                <div className="avtar-img">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" id="dropdown-basic">
                                            <img src="assets/Image/avtar.png" alt='' />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <DropdownItem as={Link} to="#" onClick={handleLogout}>
                                                <IoLogOut /> Logout
                                            </DropdownItem>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                <div className="bottom">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className='justify-content-center'>
                        <Nav className="">
                            <Nav.Link as={Link} to="/operatordesktop">All Item List</Nav.Link>
                            <Nav.Link as={Link} to="/operatornewdeal">Add Coupons</Nav.Link>
                            <Nav.Link as={Link} to="/operatorsubsription">Subscription</Nav.Link>
                            <Nav.Link as={Link} to="/operatoradspromotion">Ads Promotion</Nav.Link>
                            <Nav.Link as={Link} to="/operatordraftcupon">Draft coupons</Nav.Link>
                            <Nav.Link as={Link} to="/operatormycupons">My coupons</Nav.Link>
                        </Nav>
                        <div className="search">
                            <input type="text" name="search" id="search" placeholder='Search for the coupons, deals, and offers' />
                            <button><FaSearch /></button>
                        </div>
                    </Navbar.Collapse>
                </div>
            </Navbar>

            <Modal show={showModal} onHide={handleClose}>
                {modalContent}
            </Modal>
        </>
    );
};

export default OperatorHeader;
