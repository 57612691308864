import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import CuponAddPopup from './CuponAddPopup';

const Addnewdealform = () => {

    const [show, setShow] = useState(false);


   
    return (
        <>
            <div className="add-new-deals-outer">
                <div className="container">
                    <div className="add-new-deals-inner">
                        <div className="heading">
                            <h3>Add new deals</h3>
                        </div>
                        <form>
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="">Business name</label>
                                        <input type="text" name='bname' id='bname' placeholder='Business name' />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="">Trending name</label>
                                        <input type="text" name='tname' id='tname' placeholder='Enter trending name' />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="">Business Type</label>
                                        <select id="btype" name="btype">
                                            <option value="" selected disabled>select Business type</option>
                                            <option value="">Saab</option>
                                            <option value="">Fiat</option>
                                            <option value="">Audi</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="">Categories</label>
                                        <select id="categories" name="categories">
                                            <option value="" selected disabled>Enter the categories</option>
                                            <option value="">Saab</option>
                                            <option value="">Fiat</option>
                                            <option value="">Audi</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="">sub - Categories</label>
                                        <select id="subcategories" name="subcategories">
                                            <option value="" selected disabled>Enter the sub-categories</option>
                                            <option value="">Saab</option>
                                            <option value="">Fiat</option>
                                            <option value="">Audi</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="">Coupon Used</label>
                                        <select id="cuponused" name="cuponused">
                                            <option value="" selected disabled>Select coupon used type</option>
                                            <option value="">Saab</option>
                                            <option value="">Fiat</option>
                                            <option value="">Audi</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="">Registration number</label>
                                        <input type="text" id='registrationnumber' name='registrationnumber' placeholder='Registration number' />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="">No of year trading</label>
                                        <input type="text" name='tradingyear' id='tradingyear' placeholder='Enter trading year' />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="">Coupon Type</label>
                                        <select id="ctype" name="ctype">
                                            <option value="" selected disabled>select coupons type</option>
                                            <option value="">Saab</option>
                                            <option value="">Fiat</option>
                                            <option value="">Audi</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="">Minimum Amount purchase</label>
                                        <input type="text" id='miniamount' name='miniamount' placeholder='Purchase amount of coupons' />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="">Maximum Amount purchase</label>
                                        <input type="text" name='maxamount' id='maxamount' placeholder='Purchase amount of coupons' />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="">Discount amount</label>
                                        <input type="text" name='discamount' id='discamount' placeholder='Enter discount amount' />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="">Website link</label>
                                        <input type="text" name='wesite' id='website' placeholder='enter website link' />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="">Deals started date</label>
                                        <input type="date" name='startdate' id='startdate' placeholder='Enter the started date' />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="">Deals ended date</label>
                                        <input type="date" name='enddate' id='enddate' placeholder='Enter the ended date' />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="">Street 1</label>
                                        <input type="text" name='enddate' id='enddate' placeholder='Enter the street 1' />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="">Street 2</label>
                                        <input type="text" name='enddate' id='enddate' placeholder='Enter the street 2' />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="">Location</label>
                                        <input type="text" name='enddate' id='enddate' placeholder='Enter the location' />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="">State</label>
                                        <select id="state" name="state">
                                            <option value="" selected disabled>Select state</option>
                                            <option value="">Saab</option>
                                            <option value="">Fiat</option>
                                            <option value="">Audi</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="">City</label>
                                        <select id="state" name="state">
                                            <option value="" selected disabled>Select city</option>
                                            <option value="">Saab</option>
                                            <option value="">Fiat</option>
                                            <option value="">Audi</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="inputgroup">
                                        <label htmlFor="">Business Relationship</label>
                                        <select id="state" name="state">
                                            <option value="" selected disabled>select relationship</option>
                                            <option value="">Saab</option>
                                            <option value="">Fiat</option>
                                            <option value="">Audi</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 ">
                                    <div className="inputgroup">
                                        <label htmlFor="">how to use coupon</label>
                                        <textarea name="howusecupon" id="howusecupon" placeholder='Enter how to use  coupons'></textarea>
                                    </div>
                                </div>
                                <div className="col-12 ">
                                    <div className="inputgroup">
                                        <label htmlFor="">Terms & condition</label>
                                        <textarea name="condition" id="condition" placeholder='Enter terms & condition'></textarea>
                                    </div>
                                </div>
                                <div className="col-12 ">
                                    <div className="inputgroup">
                                        <label htmlFor="">Description</label>
                                        <textarea name="discription" id="discription" placeholder='Type the discription'></textarea>
                                    </div>
                                </div>
                                <div className="col-12 ">
                                    <div className="inputgroup">
                                        <label htmlFor="">Upload image</label>
                                        <div class="upload-image">
                                            <p>Upload The Images</p>
                                            <img src="assets/Image/uploadimg.png" alt="" />
                                            <input type="file" accept="image/*" />
                                        </div>
                                    </div>

                                </div>
                                <div className="col-12">
                                    <div className="checkgroup">
                                        <input type="checkbox" name="" id="" />
                                        <label htmlFor=""><b>Note :</b> When you purchase this coupon you can get some loyalty point </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="submit-button">
                                        <Link onClick={() => {setShow(true)}}>save</Link>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={() =>{setShow(false)}}>
                <CuponAddPopup close={() => {setShow(false)}}/>
            </Modal>
        </>
    )
}

export default Addnewdealform
