import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { FaAngleRight } from "react-icons/fa6";
import ApiService from '../../../services/Api'; // Assuming ApiService is set up similarly to TypeOfCategory
import { Spin } from 'antd'; 
import Loader from '../../Loader';
const CashBack = () => {
    const [items, setItems] = useState([]); // State to store the fetched data
    const [loading, setLoading] = useState(true); // Loading state
    const [errors, setErrors] = useState(null); // To handle any errors

    const settings = {
        slidesToShow:4,
        slidesToScroll: 1, // Scroll one slide at a time
        infinite: false, // Disable infinite looping to prevent repeating data
        arrows: true, // Show navigation arrows
        dots: false, // Disable dots pagination
        rows: 1, // Single row of slides
        responsive: [
            {
                breakpoint: 1200, 
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    // Fetching the data when the component mounts
    useEffect(() => {
        const fetchCashBackItems = async () => {
            try {
                const result = await ApiService.getCashBackItems(); // Adjust the API call as needed
                if (result.status === 200 || result.status === 201) {
                    setItems(result.data); // Set the fetched items to state
                } else {
                    
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false); // Set loading to false after the request completes
            }
        };

        fetchCashBackItems();
    }, []); // Empty dependency array means this will run once on mount

    if (loading) {
        
        return <Loader />;
    }

    if (errors) {
        return <p>{errors}</p>; // Error message
    }

    // If the items array is empty, show a message inside the slider
    const noStoresAvailable = items.length === 0;

    return (
        <div className="cashback-outer">
            <div className="container">
                <div className="cashback-inner">
                    <div className="heading">
                        <h3>Top Cash Back Stores</h3>
                        <Link to="/cashbackoffer" className='viewall'>
                            View All <FaAngleRight />
                        </Link>
                    </div>
                    <Slider {...settings}>
                        {noStoresAvailable ? (
                            <div className="item no-stores">
                                <div className="item-inner">
                                    <p>No stores available at the moment</p> {/* Message for empty data */}
                                </div>
                            </div>
                        ) : (
                            items.map((item, index) => (
                                <div className="item" key={index}>
                                    <div className="item-inner">
                                        <span className="offer-sale">
                                            {item.amounttype === 'percentage' 
                                                ? `Up to ${item.discamount}% off` 
                                                : `Up to $${item.discamount} off`}
                                        </span>
                                        <div className="img-content">
                                            <img src={item.image_url ? item.image_url : '/assets/Image/no-image-icon-23485.png'} 
                                                 alt={item.shop_name} /> 
                                        </div>
                                        <div className="text-content">
                                            <Link to={`/cupondescription/${item.id}`} className='button'>
                                                {item.amounttype === 'percentage' 
                                                    ? `Earn up to ${item.discamount}% rewards` 
                                                    : `Earn up to $${item.discamount} rewards`}
                                            </Link>
                                            <Link to={`/cupondescription/${item.id}`} className='rewards'>
                                                {item.rewards_text ? item.rewards_text : 'Rewards Rates & terms'}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default CashBack;
