import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ApiService from '../../../../services/Api'; // Assuming you have an API service
import Loader from '../../../Loader';
import { Spin } from 'antd'; // For showing a loading spinner

const BankAccount = () => {
  const [formData, setFormData] = useState({
    accountName: '',
    accountNumber: '',
    mobileNumber: '',
    ifscCode: '',
    upiId: '',
  });
  const [editMode, setEditMode] = useState(false); // Toggle between view and edit mode
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [hasBankAccount, setHasBankAccount] = useState(false);

  useEffect(() => {
    // Fetch existing bank account information if available
    const fetchBankAccountData = async () => {
      try {
        const result = await ApiService.getBankAccountDetails(); // Assuming this API fetches bank account details
        if (result.status === 200 || result.status === 201) {
          setFormData({
            accountName: result.data.account_holder_name || '',
            accountNumber: result.data.account_number || '',
            mobileNumber: result.data.mobile_number || '',
            ifscCode: result.data.ifsc_code || '',
            upiId: result.data.upi_number || '',
          });
          setHasBankAccount(true); // Indicate that data exists
          setEditMode(true); // Automatically set to edit mode if data exists
        }
      } catch (err) {
        setErrors({ message: err.message || 'Failed to fetch bank account data' });
      }
    };

    fetchBankAccountData();
  }, []);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Validation function
  const validateForm = () => {
    const errors = {};

    // Account Name Validation
    if (!formData.accountName) {
      errors.accountName = 'Account Name is required';
    }

    // Account Number Validation (Numeric and Length Validation)
    if (!formData.accountNumber) {
      errors.accountNumber = 'Account Number is required';
    } else if (!/^\d{10,18}$/.test(formData.accountNumber)) { // Account number should be between 10 to 18 digits
      errors.accountNumber = 'Account Number must be numeric and between 10 to 18 digits';
    }

    // Mobile Number Validation
    if (!formData.mobileNumber) {
      errors.mobileNumber = 'Mobile number is required';
    } else if (!/^\d{10}$/.test(formData.mobileNumber)) { // Mobile number must be 10 digits
      errors.mobileNumber = 'Mobile number must be 10 digits';
    }

    // IFSC Code Validation
    if (!formData.ifscCode) {
      errors.ifscCode = 'IFSC code is required';
    } else if (!/^[A-Za-z]{4}\d{7}$/.test(formData.ifscCode)) { // IFSC code format (e.g. ABCD1234567)
      errors.ifscCode = 'IFSC code should be in the format ABCD1234567';
    }

    setErrors(errors); // Update errors state
    return Object.keys(errors).length === 0; // If no errors, return true
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Do not proceed if the form is invalid
    }

    setLoading(true);
    try {
      const result = await ApiService.submitBankAccountDetails(formData); // Submit form data to API
      if (result.status === 200 || result.status === 201) {
        setSuccessMessage('Bank Account details saved successfully!');
        setEditMode(false); // Disable edit mode after success
        setTimeout(() => {
          setSuccessMessage('');
        }, 3000);
      } else {
        setErrors({ message: 'Failed to submit bank details. Please try again.' });
      }
    } catch (err) {
      setErrors({ message: 'An error occurred while submitting the details.' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bank-outer">
      <div className="container">
        <div className="bank-inner">
          <div className="heading">
            <h3>Bank Account Details</h3>
          </div>

          {/* Show error message */}
          {errors.message && (
            <div className="error" style={{ color: 'red', marginBottom: '15px' }}>
              {errors.message}
            </div>
          )}

          {/* Show success message */}
          {successMessage && (
            <div className="success" style={{ color: 'green', marginBottom: '15px' }}>
              {successMessage}
            </div>
          )}

          {/* Form */}
          <form onSubmit={handleSubmit}>
            <div className="row">
              {/* Account Name */}
              <div className="col-12 col-sm-6">
                <div className="input-group">
                  <label htmlFor="accountName">Account Name</label>
                  <input
                    type="text"
                    name="accountName"
                    value={formData.accountName}
                    onChange={handleInputChange}
                    placeholder="Enter Account Name"
                    disabled={!editMode} // Disable input if not in edit mode
                  />
                </div>
                {errors.accountName && <span className="error">{errors.accountName}</span>}
              </div>

              {/* Account Number */}
              <div className="col-12 col-sm-6">
                <div className="input-group">
                  <label htmlFor="accountNumber">Account Number</label>
                  <input
                    type="text"
                    name="accountNumber"
                    value={formData.accountNumber}
                    onChange={handleInputChange}
                    placeholder="Enter Account Number"
                    disabled={!editMode} // Disable input if not in edit mode
                  />
                </div>
                {errors.accountNumber && <span className="error">{errors.accountNumber}</span>}
              </div>

              {/* Mobile Number */}
              <div className="col-12 col-sm-6">
                <div className="input-group">
                  <label htmlFor="mobileNumber">Mobile Number</label>
                  <input
                    type="text"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleInputChange}
                    placeholder="Enter Mobile Number"
                    disabled={!editMode} // Disable input if not in edit mode
                  />
                </div>
                {errors.mobileNumber && <span className="error">{errors.mobileNumber}</span>}
              </div>

              {/* IFSC Code */}
              <div className="col-12 col-sm-6">
                <div className="input-group">
                  <label htmlFor="ifscCode">IFSC Code</label>
                  <input
                    type="text"
                    name="ifscCode"
                    value={formData.ifscCode}
                    onChange={handleInputChange}
                    placeholder="Enter IFSC Code"
                    disabled={!editMode} // Disable input if not in edit mode
                  />
                </div>
                {errors.ifscCode && <span className="error">{errors.ifscCode}</span>}
              </div>

              {/* UPI ID */}
              <div className="col-12 col-sm-6">
                <div className="input-group">
                  <label htmlFor="upiId">Enter UPI ID Number</label>
                  <input
                    type="text"
                    name="upiId"
                    value={formData.upiId}
                    onChange={handleInputChange}
                    placeholder="Enter UPI ID number"
                    disabled={!editMode} // Disable input if not in edit mode
                  />
                </div>
              </div>
            </div>

            {/* Submit Button */}
            <div className="input-button">
              {editMode ? (
                <button type="submit" className="button" disabled={loading}>
                  {loading ? <Spin size="small" /> : 'Save'}
                </button>
              ) : (
                <button
                  type="button"
                  className="button"
                  onClick={() => setEditMode(true)} // Enable edit mode
                >
                  Edit Bank Account Details
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BankAccount;
