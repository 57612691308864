import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './Components/Footer';
import CuponDescription from './Components/Pages/CuponDescription';
import AllCouponShow from './Components/Pages/AllCouponShow';
import Subscriptionuser from './Components/Pages/Subscriptionuser';
import PaymentDetails from './Components/Pages/PaymentDetails';
import CashBackOfferPages from './Components/Pages/CashBackOfferPages';
import DiscountPage from './Components/Pages/DiscountPage';
import VouchersPages from './Components/Pages/VouchersPages';
import Home from './Components/Pages/Home';
import Dashbord from './Components/Pages/Dashbord';
import Automotive from './Components/Pages/Automotive';
import AboutUsPage from './Components/Pages/AboutUsPage';
import FaqPage from './Components/Pages/FaqPage';
import Partnerwithus from './Components/Pages/Partnerwithus';
import WishListPage from './Components/Pages/WishListPage';
import MerchantDesktop from './Components/Merchant/MerchantDesktop';
import CouponEdit from './Components/Merchant/CouponEdit';

import DraftCuponPage from './Components/Merchant/DraftCuponPage';
import MerchantNewDeals from './Components/Merchant/MerchantNewDeals';
import MeachantLoginPage from './Components/Merchant/MeachantLoginPage';
import MearchntSubscriptionPage from './Components/Merchant/MearchntSubscriptionPage';
import AdsPromostion from './Components/Merchant/AdsPromostion';
import YourCuponPage from './Components/Merchant/YourCuponPage';
import OperatorView from './Components/Merchant/OperatorView';
import ListofOperatorsPage from './Components/Merchant/ListofOperatorsPage';
import OperatorLoginPage from './Components/Merchant/OperatorLoginPage';
import Loyaltypointspage from './Components/Merchant/Loyaltypointspage';
import MearchentHome from './Components/Merchant/MearchentHome';
import MerchantCuponDescriptionPage from './Components/Merchant/MerchantCuponDescriptionPage';
import MearchntPaymentPage from './Components/Merchant/MearchntPaymentPage';
import OperatorDesktop from './Components/Operator/OperatorDesktop';
import OperatorNewDealsPage from './Components/Operator/OperatorNewDealsPage';
import OperatorDraftCuponspage from './Components/Operator/OperatorDraftCuponspage';
import OperatorMyCuponPage from './Components/Operator/OperatorMyCuponPage';
import MearchantStaticsPage from './Components/Merchant/MearchantStaticsPage';
import OperatorCuponDescription from './Components/Operator/OperatorCuponDescription';
import OperatorSubscriptionPage from './Components/Operator/OperatorSubscriptionPage';
import OperatorPymentpage from './Components/Operator/OperatorPymentpage';
import OperatorAdsPrmotionPage from './Components/Operator/OperatorAdsPrmotionPage';
import OperatorStaticPage from './Components/Operator/OperatorStaticPage';
import OperatorLoyaltyPoins from './Components/Operator/OperatorLoyaltyPoins';
import OperatorsListPage from './Components/Operator/OperatorsListPage';
import ScannerOptionPage from './Components/Operator/ScannerOptionPage';
import OperatorsLoginPage from './Components/Operator/OperatorsLoginPage';
import CashierScanner from './Components/Shoper/CashierScanner';
import ProtectedRoute from './Components/ProtectedRoute';
import UnauthorizedPage from './Components/UnauthorizedPage'
import { AuthProvider } from './context/AuthContext'; 
import CashierDashboard from './Components/Pages/CashierDashboard';  
import UserDashboard from './Components/Pages/UserDashboard';  
import UserDataFetcher from './Components/UserDataFetcher';
import SubCategoryPage from './Components/User/Home/SubCategoryPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
          <UserDataFetcher />
  <BrowserRouter>
    <Routes>
      {/* User Pages */}
      <Route path='/home' element={<Home />} />

<Route path='/' element={
        <ProtectedRoute 
        
          element={
            <>
              {localStorage.getItem('role') === 'user' && <UserDashboard />}
              {localStorage.getItem('role') === 'merchant' && <MearchentHome />}
              {localStorage.getItem('role') === 'manager' && <OperatorDesktop />}
              {localStorage.getItem('role') === 'cashier' && <CashierDashboard />}
            </>
          } 
          allowedRoles={['user', 'merchant', 'manager', 'cashier']} 
        />
      } />


      <Route path='/automotive' element={<ProtectedRoute element={<Automotive />} allowedRoles={['user']} />} />
      <Route path="/category/:slug/:id" element={<SubCategoryPage />} /> 
      <Route path="/subcategory/:catid/:subid" element={<AllCouponShow />} /> 
      <Route path='/cashbackoffer' element={<CashBackOfferPages />} />
      <Route path='/dicountcoupon' element={<DiscountPage />}  />
      <Route path='/voucher' element={<VouchersPages />} />
      {/* <Route path='/cupondescription' element={<ProtectedRoute element={<CuponDescription />} allowedRoles={['user']} />} /> */}
      <Route path='/cupondescription/:couponid' element={<ProtectedRoute element={<CuponDescription />} allowedRoles={['user']} />} />
      
      <Route path='/subscription' element={<ProtectedRoute element={<Subscriptionuser />} allowedRoles={['user']} />} />
      <Route path='/paymentdetails/:planId' element={<ProtectedRoute element={<PaymentDetails />} allowedRoles={['user']} />} />

    
      <Route path='/dashbord' element={<ProtectedRoute element={<Dashbord />} allowedRoles={['user']} />} />
      <Route path='/aboutus' element={<AboutUsPage />} />
      <Route path='/faq' element={<FaqPage />} />
      <Route path='/partnerwithus' element={<Partnerwithus />} />
      <Route path='/wishlist' element={<ProtectedRoute element={<WishListPage />} allowedRoles={['user']} />} />

      <Route path='/coupons/:id' element={<ProtectedRoute element={<CouponEdit />} allowedRoles={['user']} />} />

      {/* Merchant Pages */}
      <Route path='/merchanthome' element={<ProtectedRoute element={<MearchentHome />} allowedRoles={['user']} />} />
      <Route path='/merchantsignup' element={<MeachantLoginPage />} />
      <Route path='/merchantdesktop' element={<MerchantDesktop />} />
      <Route path='/newdeals' element={<ProtectedRoute element={<MerchantNewDeals />} allowedRoles={['merchant']} />} />
      <Route path='/draftcupon' element={<ProtectedRoute element={<DraftCuponPage />} allowedRoles={['merchant']} />} />
      <Route path='/adsprmotiondescription/:couponid' element={<MerchantCuponDescriptionPage />} />
      <Route path='/mearchntsubscription' element={<ProtectedRoute element={<MearchntSubscriptionPage />} allowedRoles={['merchant']} />} />
      <Route path='/mearchntpayment' element={<ProtectedRoute element={<MearchntPaymentPage />} allowedRoles={['merchant']} />} />
      <Route path='/adsprmotion' element={<ProtectedRoute element={<AdsPromostion />} allowedRoles={['merchant']} />} />
      <Route path='/yourcupon' element={<ProtectedRoute element={<YourCuponPage />} allowedRoles={['merchant']} />} />
      <Route path='/opertors' element={<ProtectedRoute element={<ListofOperatorsPage />} allowedRoles={['merchant']} />} />
      <Route path='/opertorslogin' element={<OperatorLoginPage />} />
      <Route path='/loyaltypoints' element={<ProtectedRoute element={<Loyaltypointspage />} allowedRoles={['merchant']} />} />
      <Route path='/mearchntstatics' element={<ProtectedRoute element={<MearchantStaticsPage />} allowedRoles={['merchant']} />} />

      {/* Operator Pages */}
      <Route path='/operatordesktop' element={<ProtectedRoute element={<OperatorDesktop />} allowedRoles={['manager']} />} />
      <Route path='/operatornewdeal' element={<ProtectedRoute element={<OperatorNewDealsPage />} allowedRoles={['manager']} />} />
      <Route path='/operatordraftcupon' element={<ProtectedRoute element={<OperatorDraftCuponspage />} allowedRoles={['manager']} />} />
      <Route path='/operatormycupons' element={<ProtectedRoute element={<OperatorMyCuponPage />} allowedRoles={['manager']} />} />
      <Route path='/operatorcupondes' element={<ProtectedRoute element={<OperatorCuponDescription />} allowedRoles={['manager']} />} />
      <Route path='/operatorsubsription' element={<ProtectedRoute element={<OperatorSubscriptionPage />} allowedRoles={['manager']} />} />
      <Route path='/operatorpayment' element={<ProtectedRoute element={<OperatorPymentpage />} allowedRoles={['manager']} />} />
      <Route path='/operatoradspromotion' element={<ProtectedRoute element={<OperatorAdsPrmotionPage />} allowedRoles={['manager']} />} />
      <Route path='/operatorstatic' element={<ProtectedRoute element={<OperatorStaticPage />} allowedRoles={['manager']} />} />
      <Route path='/operatorloyaltypoints' element={<ProtectedRoute element={<OperatorLoyaltyPoins />} allowedRoles={['manager']} />} />
      <Route path='/opertorlogin' element={<OperatorsLoginPage />} />
      <Route path='/operatormanager' element={<ProtectedRoute element={<OperatorsListPage />} allowedRoles={['manager']} />} />
      <Route path='/scanneroption' element={<ProtectedRoute element={<ScannerOptionPage />} allowedRoles={['manager']} />} />
      <Route path='/edit-operator/:id' element={<OperatorView />} />
      {/* Shoper Page */}
      <Route path='/shoper' element={<ProtectedRoute element={<CashierScanner />} allowedRoles={['cashier']} />} />

      {/* Unauthorized Page */}
      <Route path='/unauthorized' element={<UnauthorizedPage />} />

    </Routes>
    <Footer />
  </BrowserRouter>
  </AuthProvider>
);
