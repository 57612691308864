import React, { useState,useEffect } from 'react'
import { Container, Dropdown, DropdownItem, Modal, Nav, Navbar } from 'react-bootstrap';
import { FaRegBell, FaSearch } from 'react-icons/fa';
import { FaHouseUser } from 'react-icons/fa6';
import { Link,useNavigate } from 'react-router-dom';
import DeleteAccount from '../User/DeleteAccount';
import Notification from '../User/Notification';
import { IoLogOut } from 'react-icons/io5';
import { BaseUrl } from '../BaseURL/BaseUrl';
import { useAuth } from '../../context/AuthContext'; 

const MearchantHeader = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [loyaltyPoint, setLoyaltyPoint] = useState(null);
  const [profile, setProfile] = useState('');
  const [address, setAddress] = useState('');

  const handleClose = () => setShowModal(false);

  const navigate = useNavigate();
  const { logout } = useAuth(); 

  useEffect(() => { 
    
    setLoyaltyPoint(localStorage.getItem('loyalty_points'));
    const profile_pic = localStorage.getItem('profile_pic');

    if (profile_pic == "null") {
        setProfile('/assets/Image/avtar.png');
    } else {
        
        setProfile( profile_pic );
       
      }
    const adress = localStorage.getItem('location');
 
    setAddress(adress);
}, []);
  

  const handlenotificition = () => {
      setModalContent(<Notification onClose={handleClose} />)
      setShowModal(true);
  }

  const handledeleteAccount = () => 
  {
      setModalContent (<DeleteAccount onClose={handleClose}/>)
      setShowModal(true);
  }
  const handleLogout = async () => {
    try {
        // API call to log out the user
        const response = await fetch(`${BaseUrl}/merchant/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`, // Send token if needed
            },
        });

        if (response.ok) {
            localStorage.removeItem('UserData');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            localStorage.removeItem('is_approved');
            localStorage.removeItem('sub_manager');
            localStorage.removeItem('loyalty_points');
            localStorage.removeItem('profile_pic');
            localStorage.removeItem('location');
            logout(); 
            // Redirect to login page
            navigate('/home');
        } else {
            console.error('Logout failed');
        }
    } catch (error) {
        console.error('Error during logout:', error);
    }
};
const isApproved = localStorage.getItem('is_approved') === '2';
  return (
    <>
        <Navbar expand="lg" className="merchantheader">
                <div className="top">
                    <Container>
                        <div className="header-inner row">


                            <div className="logo col-2">
                                <div className="img-content">
                                    <img src="/assets/Image/logo.png" alt="Header Logo" />

                                </div>
                            </div>
                            <div className="info col-8">
                                <div className="search">
                                    <input type="text" name="search" id="search" placeholder='Search for the coupons , deals and  offers' />
                                    <button><FaSearch /></button>
                                </div>
                                <Link to={'/loyaltypoints'} className="points">
                                    <img src="/assets/Image/Coins.png" alt="Coins Logo" />
                                    <p>{loyaltyPoint??0}</p>
                                </Link>



  

                                <div className="userhouse">
                                    <Link ><FaHouseUser />
</Link>
                                </div>
                                <div className="subscribe">
                                    <Link onClick={handlenotificition}><FaRegBell />
                                    </Link>
                                </div>
                                
                                <div className="avtar-img">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" id="dropdown-basic">
                                        <img src={profile ? profile : '/assets/Image/avtar.png'} alt="Header Logo"/>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <DropdownItem as={Link} to="/dashbord">Dashboard</DropdownItem>
                                            <DropdownItem as={Link} to="/wishlist">Wishlist</DropdownItem>
                                            <DropdownItem as={Link} onClick={handledeleteAccount}>Delete Account</DropdownItem>
                               
                                            <DropdownItem as={Link} to="#" onClick={handleLogout}><IoLogOut /> Logout</DropdownItem>

                                        </Dropdown.Menu>
                                     </Dropdown>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                <div className="bottom">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className='justify-content-center'>
                    {isApproved && (
                            <>
                                <Nav.Link as={Link} to="/merchantdesktop">All Item List</Nav.Link>
                                <Nav.Link as={Link} to="/newdeals">Add Coupons</Nav.Link>
                                <Nav.Link as={Link} to="/adsprmotion">Ads Promotion</Nav.Link>
                                <Nav.Link as={Link} to="/draftcupon">Draft Coupons</Nav.Link>
                                <Nav.Link as={Link} to="/yourcupon">My Coupons</Nav.Link>
                                <Nav.Link as={Link} to="/mearchntsubscription">Subscription</Nav.Link>
                                <Nav.Link as={Link} to="/dashbord">User Dashboard</Nav.Link>
                            </>
                        )}
                        <div className="search">
                            <input type="text" name="search" id="search" placeholder='Search for the coupons , deals and  offers' />
                            <button><FaSearch /></button>
                        </div>

                    </Navbar.Collapse>
                </div>
            </Navbar >

            <Modal show={showModal} onHide={handleClose}>
                {modalContent}
            </Modal>
    </>
  )
}

export default MearchantHeader
