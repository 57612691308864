import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import ApiService from '../../../services/Api';
import { MdEditSquare } from 'react-icons/md';
import Loader from '../../Loader';

const EditOperator = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [operatorData, setOperatorData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({ name: '', email: '', operator: '' });
    const [errors, setErrors] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [profileImage, setProfileImage] = useState(null); // State for the file input

    useEffect(() => {
        if (!id) {
            console.error('Operator ID is undefined');
            return;
        }

        const fetchOperatorData = async () => {
            try {
                const result = await ApiService.getOperatorProfileById(id);
                if (result.status === 200 || result.status === 201) {
                    setOperatorData(result.data);
                    setFormData({
                        name: result.data.name,
                        email: result.data.email,
                        operator: result.data.operator || '',
                    });
                } else {
                    setErrors({ message: 'Failed to load operator data' });
                }
            } catch (err) {
                setErrors({ message: err.message || 'An error occurred' });
            } finally {
                setLoading(false);
            }
        };

        fetchOperatorData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setProfileImage(file);
    };

    const validateForm = () => {
        const errors = {};

        // Validate fields
        if (!formData.name) {
            errors.name = 'Name is required';
        }
        if (!formData.email) {
            errors.email = 'Email is required';
        }
        if (!formData.operator) {
            errors.operator = 'Operator role is required';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('name', formData.name);
            formDataToSend.append('email', formData.email);
            formDataToSend.append('operator', formData.operator);

            // Append the file if available
            if (profileImage) {
                formDataToSend.append('profileImage', profileImage);
            }

            const result = await ApiService.updateOperatorProfile(id, formDataToSend);

            if (result.status === 200 || result.status === 201) {
                setSuccessMessage('Operator updated successfully!');
                setTimeout(() => setSuccessMessage(''), 3000);
                setEditMode(false);
            } else {
                setErrors({ message: 'Failed to update operator' });
            }
        } catch (err) {
            setErrors({ message: err.message || 'An error occurred' });
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="profile-outer">
            <div className="container">
                <div className="profile-inner">
                    <div className="row">
                        <div className="col-md-3 col-12 img-content">
                            <div className="edit-icon" onClick={() => setEditMode(!editMode)}>
                                <MdEditSquare />
                            </div>
                            <div className="profile-img">
                                <img
                                    src={operatorData?.image_url || '/assets/Image/no-image-icon-23485.png'}
                                    alt="Operator Profile"
                                />
                            </div>
                            <div className="operator-name">
                                <h2>{operatorData?.name}</h2>
                            </div>
                        </div>
                        <div className="col-md-9 col-12 text-content">
                            {successMessage && <div className="success" style={{ color: 'green', marginBottom: '15px' }}>{successMessage}</div>}
                            <form onSubmit={handleSubmit} className="row" encType="multipart/form-data">
                                <div className="col-sm-6 col-12">
                                    <label htmlFor="name">Operator Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formData?.name || ''}
                                        onChange={handleChange}
                                        placeholder="Enter Operator Name"
                                        disabled={!editMode}
                                    />
                                    {errors.name && <span className="error">{errors.name}</span>}
                                </div>

                                <div className="col-sm-6 col-12">
                                    <label htmlFor="email">E-mail ID</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData?.email || ''}
                                        onChange={handleChange}
                                        placeholder="Enter E-mail ID"
                                        disabled={!editMode}
                                    />
                                    {errors.email && <span className="error">{errors.email}</span>}
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="input-group">
                                        <label htmlFor="operator">Select the operator</label>
                                        <select
                                            className="form-control"
                                            id="operator"
                                            name="operator"
                                            value={formData.operator || ''}
                                            onChange={handleChange}
                                            disabled={!editMode}
                                        >
                                            <option value="" disabled>Select the operator</option>
                                            <option value="manager">Operator Manager</option>
                                            <option value="cashier">Operator Shop Enter Man</option>
                                        </select>
                                        {errors.operator && <span className="error">{errors.operator}</span>}
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    {editMode && (
                                        <>
                                            <label htmlFor="profileImage">Upload Profile Image</label>
                                            <input
                                                type="file"
                                                id="profileImage"
                                                name="profileImage"
                                                onChange={handleFileChange}
                                            />
                                            {profileImage && (
                                                <div className="file-preview">
                                                    <img
                                                        src={URL.createObjectURL(profileImage)}
                                                        alt="Profile Preview"
                                                        style={{ width: '100px', height: '100px' }}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>

                                <div className="col-12 submit-form">
                                    {editMode ? (
                                         <Link onClick={handleSubmit}>Save</Link>
                                    ) : (
                                        <Link type="button" className="button" onClick={() => setEditMode(true)}>Edit</Link>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditOperator;
