import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ApiService from "../../../services/Api";
import Loader from "../../Loader";

const VoucherWishList = () => {
  const [vouchers, setVouchers] = useState([]); // State to hold voucher data
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    const fetchVouchers = async () => {
      setLoading(true);
      try {
        // Fetch voucher data from the API
        const result = await ApiService.getwishlisttypeData("voucher"); // Adjust the API endpoint if needed
        setVouchers(result.data); // Assuming the API response has the voucher data in `data`
      } catch (err) {
        if (err.status === 404) {
            setError("Wihslit voucher offers not found.");
          } else if (err.status === 422) {
          setError("Invalid data received.");
        } else {
          setError("Failed to load voucher offers.");
        }
      } finally {
        setLoading(false); // Stop loading once data is fetched or error occurs
      }
    };

    fetchVouchers(); // Call the function to fetch vouchers on component mount
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  // Display loading spinner while fetching data
  if (loading) {
    return <Loader />;
  }

  // Display error message if an error occurred during data fetching
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="vouchercupon-outer voucher-outer">
      <div className="container">
        <div className="vouchercupon-inner">
          <div className="heading">
            <h3>Voucher Coupons</h3>
          </div>
          <div className="row">
            {/* Map through the vouchers and display them dynamically */}
            {vouchers.map((voucher, index) => (
              <div className="item col-md-4 col-xl-3 col-12 col-sm-6" key={index}>
                <div className="item-inner" style={{ backgroundColor: `${voucher.bgimg || '#A9D5B2'}` }}>
                  <div className="text-content">
                    <h3>{voucher.name}</h3>
                    <span>Rs ({voucher.miniamount} - {voucher.maxamount})</span>
                    <p className="cashback">
                      {voucher.amounttype === "percentage"
                        ? `Get Rs ${Math.round(voucher.discamount)}% off`
                        : `Get Rs ${Math.round(voucher.discamount)} off`}
                    </p>
                    <p className="link-button">
                      <Link to={`/cupondescription/${voucher.id}`} className="button">
                        Get Now
                      </Link>
                    </p>
                  </div>
                  <div className="img-content">
                    <img
                      src={voucher.image_url ? voucher.image_url : "/assets/Image/no-image-icon-23485.png"}
                      alt={voucher.name}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoucherWishList;
