import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ApiService from '../../../../services/Api';  // Import your ApiService
import Loader from '../../../Loader'; // Assuming you have a custom Loader component

const VoucherCupon = () => {
    const [voucherData, setVoucherData] = useState([]);  // State to store voucher data
    const [loading, setLoading] = useState(false);       // State to track loading status
    const [error, setError] = useState(null);            // State to track errors

    // Fetch voucher data when component mounts
    useEffect(() => {
        const fetchVoucherData = async () => {
            setLoading(true); // Set loading to true before making the request
            try {
                const result = await ApiService.PurchasedCoupontype('voucher', 'used');  // API call for vouchers data
                setVoucherData(result.data); // Set the fetched data into the state
            } catch (err) {
                if (err.response && err.response.status === 404) {
                    setError('No voucher offers found.');
                } else if (err.response && err.response.status === 422) {
                    setError('Invalid request.');
                } else {
                    setError('Failed to load voucher offers.');
                }
            } finally {
                setLoading(false); // Set loading to false after the request finishes
            }
        };

        fetchVoucherData(); // Call the fetch function on component mount
    }, []); // Empty dependency array to run the effect only once when the component mounts

    // Show loader while fetching data
    if (loading) {
        return <Loader />;  // Display loader while fetching
    }

    // Show error message if there was an issue fetching data
    if (error) {
        return <div>{error}</div>;  // Display error if there was one
    }
    if (voucherData.length === 0) {
        return (
            <div className="no-data mt-3 mb-3">
                <h3>No Used Vouchers Available</h3>
                <p>Sorry, there are no vouchers used at the moment.</p>
            </div>
        );
    }

    return (
        <div className="vouchercupon-outer">
            <div className="container">
                <div className="vouchercupon-inner">
                    <div className="heading">
                        <h3>Vouchers & Coupons</h3>
                    </div>
                    <div className="row">
                        {/* Loop over the dynamic voucherData */}
                        {voucherData.map((voucher, index) => (
                            <div className="item col-md-4 col-xl-3 col-12 col-sm-6" key={index}>
                                <div className="item-inner">
                                    <div className="text-content">
                                        <h3>{voucher.name}</h3>
                                        <span>Rs {Math.round(voucher.miniamount)} - {Math.round(voucher.maxamount)}</span>
                                        <p className='cashback'>
                                        {voucher.amounttype === 'percentage' 
                                            ? `Get to ${Math.round(voucher.discamount)}% off` 
                                            : `Get to $${Math.round(voucher.discamount)} off`}
                                            
                                            </p>
                                        <p className="link-button">
                                            <Link to={`/cupondescription/${voucher.id}`} className="button">
                                            {(voucher.unique_code)} 
                                            </Link>
                                        </p>
                                    </div>
                                    <div className="img-content">
                                        <img 
                                            src={voucher.image_url || '/assets/Image/no-image-icon-23485.png'} 
                                            alt={voucher.name} 
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VoucherCupon;
