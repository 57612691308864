import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ApiService from '../../services/Api'; // Adjust the import based on your file structure
import { Spin } from 'antd'; 
import Loader from '../Loader';
const Discountcoupon = () => {
    const [coupons, setCoupons] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCoupons = async () => {
            setLoading(true);
            try {
                const result = await ApiService.getCoupontypeData('discount'); // Fetch coupons without any parameters
                setCoupons(result.data); // Assuming your API returns the data in `data` field
            } catch (err) {
                if (err.response && err.response.status === 404) {
                    setError(err.response.data.errors);
                }
                else if( err.status === 404){
                        setError(err.errors);
                
                }else if( err.status === 422){
                    setError(err.errors);
            
                 } else {
                    setError('Failed to load coupons.');
                }
            } finally {
                setLoading(false);
            }
        };
        fetchCoupons();
    }, []); // Empty dependency array to run effect only once on component mount

    if (loading) {
        
        return <Loader />;
    }

    if (error) {
        return <div> {error}</div>;
    }

    return (
        <div className="discountcoupon-outer">
            <div className="container">
                <div className="discountcoupon-inner row">
                    {coupons.map((coupon, index) => (
                        <div className="item" key={index}>
                            <div className="item-inner">
                                <div className="img-content">
                                    <img src={coupon.image_url ? coupon.image_url : '/assets/Image/no-image-icon-23485.png'}  alt={coupon.name} />
                                </div>
                                <div className="dicount-text-content">
                                    <p>{coupon.name}</p>
                                    <div className="cupon-button">
                                        <Link to={`/cupondescription/${coupon.id}`} className='cupon-code-button'>
                                            <span className='cupon'>Get Coupons</span>
                                            <span className='code'>xxxx</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Discountcoupon;
